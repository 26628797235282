@import "/src/styles/colors.scss";

.user-profile-container {
  max-width: 940px;
  margin: 0 auto;
  padding: 0 50px;

  .user-banner {
    margin: 30px 0;

    .user-profile-image-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 15px auto;

      .image-wrapper {
        margin: 0;
        width: 120px;
        height: 120px;
        border: 1px solid $lighter-gray;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .default-image {
        background-color: $light-gray;
        color: $black;

        svg {
          width: 90px;
          height: 90px;
        }
      }
    }

    .user-name {
      font-size: 27px;
      line-height: 32px;
      font-weight: 700;
      color: $black;
      margin: 0 auto 10px;
      text-align: center;
    }

    .user-conversion-points {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      column-gap: 5px;

      img {
        width: 16px;
        height: 16px;
      }

      p {
        font-size: 22px;
        color: $black;
        margin: 0;
      }
    }
  }

  .profile-nav-menu {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    column-gap: 30px;
    padding: 15px 0;
    margin: 20px 0 50px;
    border-bottom: 1px solid $lighter-gray;
    width: 100%;
  }

  .profile-nav-menu.mobile {
    display: block;
    padding: 30px;
    margin: 0;
    margin-top: 80px;
    border-bottom: 0;
    width: auto;

    & > button {
      width: 100%;
      padding: 20px 0;
      justify-content: flex-start;
      border-bottom: 1px solid $lighter-gray;
    }
  }
  .tab-title-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px auto;
    max-width: 400px;

    & > button {
      position: absolute;
      left: 0px;
    }

    & > span {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: $black;
    }
  }

  .account-details-container {
    .form-wrapper {
      display: flex;
      justify-content: center;
      column-gap: 25px;
      row-gap: 25px;

      &.justify-left {
        justify-content: left;
      }

      .column {
        display: flex;
        flex-direction: column;
        row-gap: 25px;
        flex: 1;
        max-width: 400px;

        .row {
          display: flex;
          column-gap: 15px;

          & > div {
            flex: 1 0 48%;
          }

          input#gender-select {
            text-transform: capitalize;
          }
        }
      }
    }
    .upload-avatar-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      border: 1px dashed #333;
      border-radius: 10px;
      overflow: hidden;
    }
    .upload-avatar-label {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 20px 0;
      cursor: pointer;
    }
    .upload-avatar-label p {
      margin: 10px 0 0 0;
    }
    .upload-avatar-container .image-wrapper img {
      width: 100%;
      height: 100%;
    }
  }

  @media screen and (max-width: 767px) {
    .account-details-container {
      text-align: center;

      & > button {
        width: 100%;
        max-width: 400px;
      }

      .form-wrapper {
        flex-direction: column;
        align-items: center;

        .column {
          width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: 400px) {
    .profile-nav-menu.mobile {
      padding: 0;
    }
  }
}

@media screen and (max-width: 767px) {
  .user-profile-container {
    padding: 0 20px;
  }
}
