@import "/src/styles/colors.scss";

.edit-survey-row {
  display: flex;
  margin: 30px auto;
  column-gap: 20px;

  & > div {
    max-width: 344px;
    width: 100%;
  }
}

.tab {
  display: flex;
  flex-direction: row;
  margin-top: 36px;
}

.tab-title {
  margin-right: 36px;
  cursor: pointer;
  font-weight: 700;
  color: #114556;
}

.tab-title__disabled {
  color: #91a3a9;
}

.data-block-container {
  display: flex;
  flex-direction: column;
  column-gap: 10px;
}

.upload-image-container {
  margin: 20px 0 40px;

  .image-wrapper {
    width: 350px;

    & > img {
      width: 100%;
      border-radius: 16px;
    }
  }
  .remove-image-button {
    text-transform: capitalize;
    color: $main-blue;
    border: 1px solid $main-blue;
    margin-top: 10px;
  }

  .upload-image-label {
    cursor: pointer;
    width: fit-content;
    color: $main-blue;
    border: 1px dashed $main-blue;
    border-radius: 16px;
    padding: 50px 120px;

    display: flex;
    flex-direction: column;
    align-items: center;

    & > p {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      margin: 0;
    }

    & > svg {
      width: 32px;
      height: 32px;
    }
  }
}

.upload-users-container {
  display: flex;
  column-gap: 15px;
  flex-wrap: nowrap;
  justify-content: space-between;

  .upload-users-label {
    cursor: pointer;
    width: fit-content;
    height: 30px;
    color: $white;
    background-color: $main-blue;
    border: 1px solid $main-blue;
    border-radius: 4px;
    padding: 5px 15px;
    display: flex;
    align-items: center;
    column-gap: 8px;

    & > p {
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      margin: 0;
    }

    & > svg {
      width: 20px;
      height: 20px;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  .remove-users-button {
    text-transform: capitalize;
    color: $inactive-red;
    border: 1px solid $inactive-red;
    margin: 0;
    padding: 10px 24px;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;

    &:hover {
      color: $inactive-red;
      border: 1px solid $inactive-red;
      background-color: $white;
      opacity: 0.7;
    }
  }
}

.targeting-titles {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-align: left;
  margin-bottom: 4px;
  margin-top: 0;
}

.checkbox-label {
  justify-content: start;
}

.input-label {
  color: var(--light-gray, #bac6ca);
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.5px;
}

.input-container-survey {
  display: flex;
  flex-direction: column;
}

.date-picker-lable {
  color: var(--black, #384144);
}

.status-input {
  max-width: 344px;
  width: 100%;
}

.multiplier-block {
  background-color: #f8fafa;
  max-width: 450px;
  width: 100%;
  border-radius: 16px;
  padding: 20px;
}

.multiplier-container {
  max-width: 144px;
  width: 100%;
}

.target-container {
  max-width: 144px;
  width: 100%;
}

.target-box__chip-block {
  position: absolute;
  top: 30px;
}

.target-container__title {
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-top: 0;
}

.target-container__uploaded-list {
  display: flex;
  justify-content: space-between;
  max-width: 304px;
  width: 100%;
  margin-bottom: 12px;
}

.target-container__csv-remove-icon {
  max-width: 18px;
  width: 100%;
  max-height: 18px;
  height: 100%;
  filter: invert(55%) sepia(99%) saturate(801%) hue-rotate(163deg) brightness(103%) contrast(87%);
  cursor: pointer;
}

.target-container__uploaded-list__text {
  color: #5f6f74;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  padding-left: 30px;
}

.target-container__response-rate {
  margin-left: 36px;
  display: "flex";
  column-gap: "10px";
}

.target-container__response-rate__title {
  padding-top: 20px;
}

.target-container__response-rate__text {
  align-self: center;
  color: #dce2e4;
}

.target-container__response-rate__name {
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.5px;
}

.target-container__response-rate__gap {
  margin-right: 14px;
}

.target-container__target-single-user__text {
  align-self: center;
}

.target-container__target-single-user__name {
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.5px;
}

.last-container {
  display: flex;
  justify-content: space-between;
}

.last-container__button {
  text-transform: capitalize;
  background-color: #2ebdee;
  border: 1px solid #2ebdee;
  color: #f8fafa;
  border-radius: 4px;
  padding: 10px 24px;
  height: fit-content;
  max-width: 214px;
  width: 100%;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  align-self: end;
  cursor: pointer;

  &:hover {
    background: "#2EBDEE";
    opacity: "0.7";
  }
  &:disabled {
    border: 1px solid $border-gray;
    background: $lighter-gray;
    color: $text-gray;
    cursor: not-allowed;
    opacity: 0.6;
    box-shadow: none;

    &:hover {
      background: $lighter-gray;

      &:active {
        background: $lighter-gray;
      }
    }
  }
}

.reminder {
  font-style: italic;
}

.notify-users__csv-block {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.notify-users__csv-block__button {
  white-space: nowrap;
}

.notify-users__csv-checkbox {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
}

.notify-users__csv-block__text {
  flex-grow: 1;
}

.css-aaw9q8-MuiInputBase-root .MuiInputBase-input {
  border: 0.1px solid #bac6ca !important;
}

.css-aaw9q8-MuiInputBase-root .MuiInputBase-input:disabled {
  border: none !important;
}

.preview-card-container {
  max-width: 368px;
  width: 100%;
}

.preview-card__survey-card {
  max-width: unset;
}

.force-notifications span {
  padding-left: 0;
}

.warning-text {
  color: $warning;
}
