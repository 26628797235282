.progress-dialog-body {
  padding: 2rem;
  width: 300px;
  max-width: 100%;
}

.progress-dialog-title {
  text-align: center;
  margin-bottom: 1rem;
}
