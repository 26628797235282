@import "src/styles/colors";
.contact-us-button {
  width: 60px;
  height: 60px;
  border: none;
  border-radius: 50%;
  background-color: $orange;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 40px;
  right: 40px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  @media screen and (max-width: 600px) {
    bottom: 30px;
    right: 16px;
  }
}
