.source-tab-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.source-tab-name-col {
  min-width: 12rem;
}

.source-tab-type-col {
  min-width: 10rem;
}

.source-tab-value-col {
  min-width: 12rem;
}

.source-tab-action-icon {
  cursor: pointer;
}

.source-tab-title {
  margin-right: 36px;
  font-weight: 700;
  color: #114556;
  display: block;
}

.hidden {
  display: none !important;
}
