.discountApplied {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding-right: 8px;
  display: flex;
  align-items: center;

  .icon {
    cursor: pointer;
    color: rgb(250, 5, 5);
    margin-left: 8px;
  }
}
