@import "/src/styles/colors";

.container {
    width: 343px;
    height: 367px;
    display: grid;
    grid-template-columns: 42.875px 42.875px 42.875px 42.875px 42.875px 42.875px 42.875px 42.875px;
    grid-template-rows: 45.875px 45.875px 45.875px 45.875px 45.875px 45.875px 45.875px 45.875px;
}

.confetti-1 {
    height: 5.729159211734919px;
    width: 10.305545297755106px;
    background: $active-green;
    transform: rotate(-76.84deg);
    border-radius: 5px;
    grid-column-start: 3;
    grid-row-start: 1;
    margin-left: 27px;
    margin-top: 26px;
}

.confetti-2 {
    height: 5.68px;
    width: 5.68px;
    background: $orange;
    border-radius: 5px;
    grid-column-start: 4;
    grid-row-start: 2;
    margin-left: 32px;
}

.confetti-3 {
    height: 5.729159211734919px;
    width: 10.305545297755106px;
    background: $inactive-red;
    transform: rotate(36.33deg);
    border-radius: 5px;
    grid-column-start: 5;
    grid-row-start: 1;
    margin-left: 34px;
    margin-top: 37px;
}

.title-survey-completed {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 2;
    grid-row-end: 2;
    text-align: center;
}

.confetti-4 {
    height: 5.729159211734919px;
    width: 10.305545297755106px;
    background: $orange;
    transform: rotate(36.33deg);
    border-radius: 5px;
    grid-column-start: 2;
    grid-row-start: 3;
    margin-top: 37px;
}

.confetti-5 {
    height: 5.7291592117px;
    width: 10.3055452978px;
    background: $inactive-red;
    transform: rotate(36.33deg);
    border-radius: 5px;
    grid-column-start: 3;
    grid-row-start: 3;
    margin-left: 23px;
    margin-top: 17px;
}

.confetti-6 {
    height: 5.68px;
    width: 5.68px;
    background: $orange;
    border-radius: 5px;
    grid-column-start: 4;
    grid-row-start: 3;
    margin-left: 16px;
    margin-top: 37px;
}

.confetti-7 {
    width: 10.31px;
    height: 5.73px;
    background: $active-green;
    border-radius: 5px;
    grid-column-start: 5;
    grid-row-start: 3;
    margin-left: 7px;
    margin-top: 21px;
    transform: rotate(-76.84deg);
}

.confetti-8 {
    width: 10.31px;
    height: 6.44px;
    background: $orange;
    border-radius: 5px;
    grid-column-start: 6;
    grid-row-start: 3;
    margin-left: 18px;
    margin-top: 11px;
    transform: rotate(-60.84deg);
}

.confetti-9 {
    width: 10.31px;
    height: 10.31px;
    background: #EEBE2E;
    border-radius: 8px;
    grid-column-start: 3;
    grid-row-start: 4;
    margin-left: 27px;
}

.confetti-10 {
    width: 6.44px;
    height: 6.44px;
    background: $active-green;
    border-radius: 6px;
    grid-column-start: 6;
    grid-row-start: 4;
    margin-left: 11px;
    margin-top: 3px
}

.confetti-11 {
    height: 7.73px;
    width: 7.73px;
    background: $orange;
    border-radius: 8px;
    grid-column-start: 7;
    grid-row-start: 4;
    margin-left: 32px;
    margin-top: 8px;
}

.points-icon {
    // margin-top: 20px;
    width: 172px;
    height: 100px;
    grid-column-start: 3;
    grid-column-end: 6;
    grid-row-start: 4;
    grid-row-end: 6;
}

.points-amount {
    margin-top: 20px;
    padding: 0;
    font-size: 24px;
    font-weight: bold;
    color: $orange;

    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 6;
    grid-row-end: 6;
    width: 85.75px;
    text-align: center;
}

.spinner-points-small {
    margin: 0;
    padding: 0;
    color: $orange;

    grid-column-start: 3;
    grid-column-end: 3;
    grid-row-start: 6;
    grid-row-end: 6;
    width: 171.5px;
    text-align: center;

    padding-top: 30px;
}

.survey-points-small {
    margin: 0;
    padding: 0;
    color: $orange;

    grid-column-start: 1;
    grid-column-end: 8;
    grid-row-start: 7;
    grid-row-end: 7;
    // min-width: 173px;
    width: inherit;
    text-align: center;

    padding-top: 10px;
}

.points-total-sum-large {
    padding: 0;
    font-size: 24px;
    font-weight: bold;
    color: $orange;

    grid-column-start: 3;
    grid-column-end: 3;
    grid-row-start: 8;
    grid-row-end: 8;
    width: 171.5px;
    text-align: center;
}

.points-amount-single {
    margin-top: 25px;
    padding: 0;
    font-size: 24px;
    font-weight: bold;
    color: $orange;

    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 6;
    grid-row-end: 6;
    width: 85.75px;
    text-align: center;
}

.points-text-single {
    margin: 0;
    margin-top: 5px;
    padding: 0;
    color: $orange;

    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 7;
    grid-row-end: 7;
    width: 85.75px;
    text-align: center;
}


.spinner-points-amount {
    margin-top: 20px;
    padding: 0;
    font-size: 24px;
    font-weight: bold;
    color: $orange;

    grid-column-start: 5;
    grid-column-end: 6;
    grid-row-start: 6;
    grid-row-end: 6;
    width: 85.75px;
    text-align: center;
}

.spinner-points-text {
    margin: 0;
    padding: 0;
    color: $orange;

    grid-column-start: 5;
    grid-column-end: 6;
    grid-row-start: 7;
    grid-row-end: 7;
    width: 85.75px;
    text-align: center;
}


.confetti-12 {
    height: 5.68px;
    width: 5.68px;
    background: #EEBE2E;
    border-radius: 5px;
    grid-column-start: 2;
    grid-row-start: 5;
}

.confetti-13 {
    height: 10.31px;
    width: 5.73px;
    background: $inactive-red;
    transform: rotate(64.77deg);
    border-radius: 5px;
    grid-column-start: 3;
    grid-row-start: 5;
    margin-left: 15px;
    margin-top: 6px;
}

.confetti-14 {
    height: 10.31px;
    width: 6.44px;
    background: $orange;
    transform: rotate(-72.14deg);
    border-radius: 5px;
    grid-column-start: 7;
    grid-row-start: 5;
}

.confetti-15 {
    height: 5.7291592117px;
    width: 10.3055452978px;
    background: $inactive-red;
    transform: rotate(46.59deg);
    border-radius: 5px;
    grid-column-start: 6;
    grid-row-start: 5;
    margin-left: 13px;
    margin-top: 32px;
}

.confetti-16 {
    height: 7.73px;
    width: 7.73px;
    background: $orange;
    border-radius: 8px;
    grid-column-start: 1;
    grid-row-start: 6;
    margin-left: 19px;
}

.confetti-17 {
    width: 7.73px;
    height: 7.73px;
    background: #EEBE2E;
    border-radius: 8px;
    grid-column-start: 3;
    grid-row-start: 6;
    margin-left: 36px;
    margin-top: 7px;
}

.confetti-18 {
    height: 5.88px;
    width: 5.88px;
    background: #EEBE2E;
    border-radius: 8px;
    grid-column-start: 7;
    grid-row-start: 6;
    margin-left: 32px;
}

.confetti-19 {
    width: 6.44px;
    height: 6.44px;
    background: $active-green;
    border-radius: 6px;
    grid-column-start: 6;
    grid-row-start: 6;
    margin-left: 14px;
    margin-top: 26px;
}

.confetti-20 {
    width: 10.31px;
    height: 5.73px;
    background: $active-green;
    border-radius: 5px;
    grid-column-start: 3;
    grid-row-start: 7;
    margin-left: 4px;
    margin-top: -5px;
    transform: rotate(-51.76deg);
}

.confetti-21 {
    height: 5.7291592117px;
    width: 10.3055452978px;
    background: $orange;
    transform: rotate(29.33deg);
    border-radius: 5px;
    grid-column-start: 7;
    grid-row-start: 6;
    margin-top: 37px;
}

.confetti-22 {
    height: 5.68px;
    width: 5.68px;
    background: #EEBE2E;
    border-radius: 5px;
    grid-column-start: 4;
    grid-row-start: 8;
    margin-left: 7px;
}

.confetti-23 {
    height: 10.31px;
    width: 5.73px;
    background: $active-green;
    transform: rotate(-15.06deg);
    border-radius: 5px;
    grid-column-start: 5;
    grid-row-start: 8;
    margin-left: 21px;
}

.bonus-text {
    display: block;
    margin-top: 20px;
    font-size: 16px;
}

@media screen and (max-width: 660px) {
    .confetti-1 {
        width: 0px;
        height: 8px;
        border: 2px solid #F2805A;
        transform: rotate(0deg);
        background: #F2805A;
        border-radius: 0px;
        grid-column-start: 4;
        grid-row-start: 4;
        margin-left: 21px;
        margin-top: 19px;
    }

    .confetti-2 {
        width: 0px;
        height: 8px;
        border: 2px solid #F2805A;
        transform: rotate(90deg);
        background: #F2805A;
        border-radius: 0px;
        grid-column-start: 4;
        grid-row-start: 4;
        margin-left: 21px;
        margin-top: 19px;
    }

    .confetti-3 {
        width: 0px;
        height: 8px;
        border: 2px solid #F2805A;
        transform: rotate(0deg);
        background: #F2805A;
        border-radius: 0px;
        grid-column-start: 3;
        grid-row-start: 5;
        margin-left: 12px;
        margin-top: 26px;
    }
    .confetti-4 {
        width: 0px;
        height: 8px;
        border: 2px solid #F2805A;
        transform: rotate(90deg);
        background: #F2805A;
        border-radius: 0px;
        grid-column-start: 3;
        grid-row-start: 5;
        margin-left: 12px;
        margin-top: 26px;
    }
    .confetti-5 {
        width: 0px;
        height: 8px;
        border: 2px solid #F2805A;
        transform: rotate(90deg);
        background: #F2805A;
        border-radius: 0px;
        grid-column-start: 6;
        grid-row-start: 4;
        margin-left: 15px;
        margin-top: 28px;
    }
    .confetti-6 {
        width: 0px;
        height: 8px;
        border: 2px solid #F2805A;
        transform: rotate(0deg);
        background: #F2805A;
        border-radius: 0px;
        grid-column-start: 6;
        grid-row-start: 4;
        margin-left: 15px;
        margin-top: 28px;
    }
    .confetti-7 {
        width: 0px;
        height: 8px;
        border: 2px solid #F2805A;
        transform: rotate(0deg);
        background: #F2805A;
        border-radius: 0px;
        grid-column-start: 6;
        grid-row-start: 6;
        margin-left: 0px;
        margin-top: 11px;
    }

    .confetti-8 {
        width: 0px;
        height: 8px;
        border: 2px solid #F2805A;
        transform: rotate(90deg);
        background: #F2805A;
        border-radius: 0px;
        grid-column-start: 6;
        grid-row-start: 6;
        margin-left: 0px;
        margin-top: 11px;
    }


    .title-survey-completed {
        font-size: 32px;
        width: 171.5px;
        line-height: 125%;
        grid-column-start: 3;
        grid-column-end: 6;
        grid-row-start: 1;
        grid-row-end: 2;
    }
    .points-icon {
        width: 85.75px;
        height: 74.63px;
        grid-column-start: 4;
        grid-column-end: 5;
        grid-row-start: 5;
    }
    .points-amount{
        margin-top: 0px;
        grid-row-start: 7;
        grid-row-end: 7;
    }
    .points-text{
        margin-top: 25px;
        color: #91A3A9
    }



    .confetti-9 {
        display: none;
    }

    .confetti-10 {
        display: none;
    }

    .confetti-11 {
        display: none;
    }

    .confetti-12 {
        display: none;
    }

    .confetti-13 {
        display: none;
    }

    .confetti-14 {
        display: none;
    }

    .confetti-15 {
        display: none;
    }

    .confetti-16 {
        display: none;
    }

    .confetti-17 {
        display: none;
    }

    .confetti-18 {
        display: none;
    }

    .confetti-19 {
        display: none;
    }

    .confetti-20 {
        display: none;
    }

    .confetti-21 {
        display: none;
    }

    .confetti-22 {
        display: none;
    }

    .confetti-23 {
        display: none;
    }
  }