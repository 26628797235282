@import "/src/styles/colors.scss";

.load-more-button-wrapper {
  text-align: center;
  margin: 0px auto 60px auto;
}

.loading-text {
  margin: 220px auto 0;

  @media screen and (max-width: 768px) {
    margin: 100px auto 0;
  }
}

.survey-list {
  margin: 10px 10%;
  padding-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  column-gap: 15px;
  row-gap: 15px;

  .empty {
    margin: 80px auto;
  }
}
