$main-blue: #2ebdee;
$dark-blue: #3799b6;
$secondary-blue: #114556;
$light-blue: #d6f2fc;
$black: #384144;
$dark-gray: #5f6f74;
$gray: #91a3a9;
$light-gray: #bac6ca;
$lighter-gray: #dce2e4;
$default-black: #000000;
$white: #ffffff;
$background-gray: #f5f5f5;
$orange: #f2805a;
$background-orange: rgba(242, 128, 90, 0.3);
$category-background-gray: #f8fafa;
$active-green: #27c9a1;
$inactive-red: #ec3a5a;
$border-gray: #d0d0d0;
$text-gray: #888888;
$danger: rgba(242, 8, 8, 0.773);
$warning: #E3D026;
