.btn-disabled:disabled {
  cursor: not-allowed;
  background-color: #e0e0e0;
  border: 1px solid #e0e0e0;
  color: #9e9e9e;
}

.email-template-warning {
  font-size: 0.8rem;
  color: #f44336;
}

.email-template-preview {
  display: flex;
  gap: 2rem;
}

.email-template-params {
  min-width: max-content;
  padding: 2rem 2rem 2rem 0;

  div:not(:last-child) {
    margin-bottom: 1rem;
  }

  h4 {
    font-size: 1.1em;
    font-weight: 400;
    margin-bottom: 0.5rem;
  }

  p:not(:last-child) {
    margin-bottom: 5px;
  }

  &__unsupported {
    color: #f44336;
  }
}

.status-dialog {
  width: 30rem;
  max-width: 100%;
  padding: 2rem;

  p {
    font-size: 1.2rem;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__checkbox {
    margin-bottom: 0.5rem;
  }

  &__buttons {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
}
