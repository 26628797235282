@import "/src/styles/colors.scss";

.content-wrapper {
  margin: 60px auto;
  padding: 0 30px;
  max-width: 80%;
}

.header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

p.title {
  font-weight: bold;
  font-size: 27px;
  line-height: 32px;
  color: $secondary-blue;
  margin: 0 0 10px;
}

p.subtitle {
  font-size: 16px;
  line-height: 24px;
  color: $gray;
  margin: 0;
}

tbody {
  background-color: $white;
  box-shadow: 3px 24px 20px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
}

tbody td {
  padding: 15px;
  border-top: 1px solid $lighter-gray;
}

.pinned {
  position: relative;
}

.pin-icon {
  position: absolute;
  left: -10px;
  top: 17px;
}

.pin-icon-menu {
  rotate: 45deg;
}

.MuiMenuItem-root.MuiMenuItem-root {
  padding: 12px 27px;

  & .MuiListItemIcon-root {
    color: #91a3a9;
  }
}

.MuiPaper-root.MuiPaper-root {
  border-radius: 16px;
}

.status-cell {
  min-width: 70px;
}

.status-cell.active {
  color: $active-green;
}

.status-cell.inactive {
  color: $inactive-red;
}

.status-cell.pending {
  color: $orange;
}

.status-cell::before {
  content: "";
  display: inline-block;
  width: 3px;
  height: 3px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
  border: 2px solid;
  background-color: $white;
  margin: 1px 5px;
}

.filters-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.filter-wrapper,
.actions-button {
  position: relative;
}

.filter-options {
  padding: 0 25px 25px;
}

.filter-options {
  .option-title {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    margin: 20px 0 0;
    color: $gray;
  }

  .buttons-wrapper {
    display: flex;
    justify-content: space-evenly;
    column-gap: 30px;
    margin-top: 30px;
  }
}

.submit-pin {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  border-radius: 28px;

  .icon {
    width: 38px;
    height: 38px;
    padding-bottom: 20px;
  }

  .dialog-title {
    font-weight: 700;
    font-size: 19px;
    line-height: 24px;
    padding-bottom: 20px;
  }

  .dialog-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 48px;
  }

  .dialog-button {
    padding: 10px 114px;
    text-transform: capitalize;
  }
}
