@import "/src/styles/colors.scss";

.admin-nav-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 20px;
  margin: 0 0 40px;
  background-color: $white;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);

  .logo-wrapper {
    width: 110px;
    height: 75px;
    min-width: 110px;
    margin: 20px;
  }
  .logo-wrapper img {
    width: 100%;
    height: 100%;

    &:hover {
      cursor: pointer;
    }
  }

  .nav-menu-items-wrapper {
    display: flex;
    flex-grow: 1;
    align-items: center;
  }

  .nav-menu-item {
    display: flex;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 1px;
    color: $dark-gray;
    margin: 0 20px;
    text-transform: uppercase;
    text-decoration: none;
  }
  .nav-menu-item svg {
    width: 24px;
    height: 24px;
    margin: 0 8px;
  }

  .nav-menu-item:hover {
    cursor: pointer;
    opacity: 0.8;
    color: $default-black;
  }

  .profile-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: $dark-gray;
    margin: 0 30px;

    .image-wrapper,
    .logout-wrapper {
      min-width: 36px;
      min-height: 36px;
      max-width: 36px;
      max-height: 36px;
      margin: 0 10px;
      border: 1px solid $dark-gray;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      & > svg {
        width: 24px;
        height: 24px;
      }
    }

    .logout-wrapper {
      cursor: pointer;
      margin-left: 20px;
      min-width: 25px;
      min-height: 25px;
      max-width: 25px;
      max-height: 25px;

      & > svg {
        width: 15px;
        height: 15px;
      }
    }
  }
}
