.parameter-table-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.parameter-table-header-col {
  min-width: 12rem;
}

.parameter-table-action-icon {
  cursor: pointer;
}

.parameter-table-title {
    margin-right: 36px;
    font-weight: 700;
    color: #114556;
}
