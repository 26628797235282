@import "src/styles/colors";

.email-template-select {
  position: relative;

  &__icon {
    position: absolute;
    top: 50%;
    right: 2rem;
    transform: translateY(-50%);
    cursor: pointer;
    color: $main-blue;

    &:hover {
      color: darken($main-blue, 10%);
    }
  }
}

.email-template-warning {
  font-size: 0.9rem;
  color: $danger;
  font-weight: 500;
  margin-top: 0.5rem;
}

.email-template-preview {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  &__header {
    font-size: 1.1rem;
    font-weight: 400;
    color: $secondary-blue;
    text-align: center;
  }

  &__content {
    font-size: 1rem;
    color: $black;
    line-height: 1.5;
  }
}

.email-template-params__section {
  flex: 1;
}

.email-template-params__section--unsupported {
  color: $danger;
}

.email-template-params {
  background-color: #f8f8f8;
  border-radius: 8px;
  padding: 1.5rem;
  min-width: 300px;
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  margin: auto;

  div:not(:last-child) {
    margin-bottom: 1rem;
  }

  h4 {
    font-size: 1.1em;
    font-weight: 500;
    margin-bottom: 0.5rem;
    color: $main-blue;
  }

  p:not(:last-child) {
    margin-bottom: 5px;
  }
}
