@import "/src/styles/colors.scss";

.general-block {
  padding: 0 91px 77px 69px;
  margin-top: 32px;
}

.general-block__header {
  display: flex;
  justify-content: space-between;
}

.general-block__csv {
  align-self: flex-start;
}

.general-block__csv-text {
  color: inherit;
  text-decoration: none;
}

.general-block__title {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 40px;
}

.general-block__row-text {
  text-overflow: ellipsis;
  max-width: 260px;
  word-break: break-word;
}