@import "src/styles/colors";

#landing-page-home-wrapper {
  display: flex;
  flex-direction: column;

  .section {
    padding: 100px 0;
  }

  .center {
    text-align: center;
  }

  .underline {
    text-decoration: underline;
  }

  .bold {
    font-weight: bold;
  }

  .section-title {
    font-size: 34px;
    color: $secondary-blue;
  }

  .dark-title {
    color: $dark-blue !important;
  }

  .gray-text {
    color: $gray;
  }

  .dark-gray-text {
    color: $dark-gray;
  }

  .call-to-action .title {
    font-size: 40px;
    color: $secondary-blue;
    margin: 10px 0;
  }

  .call-to-action .text {
    font-size: 14px;
  }

  .call-to-action a {
    font-size: 12px;
    padding: 10px 35px;
  }

  .landing-banner-box {
    width: 100%;
    img {
      width: 100%;
    }
  }

  .info-box {
    margin: 0 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .info-box .info-img-wrapper {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .community {
    padding: 200px 0;
    background-color: $category-background-gray;
    background-image: url("../../../assets/landing-page/benefits-bg.svg");
    background-repeat: no-repeat;
    background-position: bottom left;
    background-size: 30%;
  }

  .community .community-icon-wrapper {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: rgba(46, 189, 238, 0.1);
  }

  .community .community-icon-wrapper img {
    width: 60%;
  }

  .community p {
    color: $secondary-blue;
  }

  .community-box {
    display: flex;
    justify-content: center;
  }

  .old-way {
    padding-bottom: 40px;
  }

  .opinions {
    padding-top: 40px;
    .opinions-list {
      display: flex;
      justify-content: center;
      margin-top: 30px;
    }
  }
  .featured .featured-list {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 70px;
  }

  @media screen and (max-width: 600px) {
    .featured .featured-list {
      flex-direction: column;
      margin-top: 10px;
    }
  
    .opinions .opinions-list {
      flex-direction: column;
      gap: 15px;
    }
  
    .auth-controls {
      margin-top: 0px;
    }
  
    .auth-controls a {
      color: white;
    }
  
    .section{
      padding: 20px 0px;
    }
  
    .featured-list img {
      padding: 10px 0px;
    }
  
    .old-way {
      padding-bottom: 0px;
    }
  
    .opinions {
      padding-top: 20px;
    }
  
    .community-box {
      flex-direction: column;
    }
  
  }
}
