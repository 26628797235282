@import "/src/styles/colors";

.awarding-bonus-text{
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: $dark-gray;
}

.bonus-text {
  display: block;
  margin-top: 20px;
  font-size: 16px;

  &__noqualify {
    max-width: 30rem;
    text-align: justify;
  }
}
