@import "../../../styles/colors";

.contact-us-button {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: $orange;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 40px;
  right: 40px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  @media screen and (max-width: 600px) {
    bottom: 30px;
    right: 16px;
  }
}

.MuiPaper-rounded:before {
  background-color: transparent !important;
}

.contact-us-paper {
  width: 390px;
  height: 505px;
  @media screen and (max-width: 600px) {
    width: 100%;
    height: 100%;
  }
  .contact-us-dialog {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.11), rgba(255, 255, 255, 0.11)), #FFFFFF;
    margin: 24px;
    display: flex;
    flex-direction: column;
    .contact-us-close {
      position: absolute;
      top: 27px;
      right: 27px;
      width: fit-content;
      cursor: pointer;
      @media screen and (max-width: 600px) {
        width: 100%;
        left: 22px;
      }
    }
    .contact-us-header {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 27px;
      line-height: 32px;
      color: #384144;
      text-align: center;
      margin-bottom: 30px;
      @media screen and (max-width: 600px) {
        margin-top: 43px;
      }
    }
    .contact-us-label {
      margin-bottom: 12px;
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.5px;
      color: #BAC6CA;
    }

    .contact-us-submit {
      border: none;
      height: 40px;
      width: 343px;
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      bottom: 40px;
      color: #FFFFFF;
      text-transform: none;

      &:hover {
        background: #2EBDEE;
        opacity: 0.7;
      }
    }

    .contact-us-input {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #384144;

      ::placeholder {
        color: #384144;
        opacity: 1;
      }
    }

    .contact-us-delimiter {
      margin: 12px 0;
      border-bottom: 1px solid #DCE2E4;
    }
  }
}