@import "/src/styles/colors.scss";

.user-details-info-line {
  margin: 15px 20px 15px 0;
}

.user-details-param {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: $gray;
  margin: 0;
}
.user-details-value {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: $black;
  margin: 0;
  max-width: 200px;
}

.user-details-value.active {
  color: $active-green;
}

.user-details-value.inactive {
  color: $inactive-red;
}

.user-details-image {
  margin: 15px 30px 15px 0;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid $lighter-gray;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &.default-image {
    background-color: $light-gray;
    color: $black;

    svg {
      width: 70px;
      height: 70px;
    }
  }
}

.surveys-completed-box,
.points-box {
  position: relative;
  background: $white;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 0 25px;
  min-height: 150px;
  min-width: 400px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  flex-grow: 1;
  margin: 15px;
  overflow: hidden;
}

.surveys-completed-box .number {
  font-weight: bold;
  font-size: 32px;
  line-height: 24px;
  color: $black;
  margin: 0 0 5px 0;
}

.points-box .number {
  font-weight: bold;
  font-size: 19px;
  line-height: 24px;
  color: $orange;
  margin: 0;
}

.surveys-completed-box .title,
.points-box .title {
  font-size: 18px;
  font-weight: normal;
  line-height: 24px;
  color: $black;
  margin: 0 0 10px 0;
}

.surveys-completed-box img {
  position: absolute;
  width: 110px;
  bottom: -10px;
  right: 20px;
  opacity: 0.2;
}

.points-box img {
  position: absolute;
  width: 130px;
  bottom: -40px;
  right: -50px;
  opacity: 0.3;
}

.points-box {
  .row {
    display: flex;
    column-gap: 70px;
    align-items: center;
  }

  .number-total {
    font-weight: bold;
    font-size: 19px;
    line-height: 24px;
    color: $gray;
    margin: 0;
  }
}

.add-points-dialog {
  width: 300px;
  padding: 35px;

  .dialog-title {
    font-size: 19px;
    line-height: 24px;
    font-weight: bold;
    color: $black;
    text-align: center;
    mix-blend-mode: normal;
    margin: 0 auto 40px;
  }

  .dialog-subtitle {
    font-size: 12px;
    line-height: 16px;
    font-weight: bold;
    color: $black;
    letter-spacing: 0.5px;
    margin: 0 0 5px;
  }

  .dialog-user-name {
    font-size: 16px;
    line-height: 24px;
    color: $dark-gray;
    mix-blend-mode: normal;
    margin: 5px auto 15px;
  }

  .buttons-wrapper {
    display: flex;
    justify-content: space-between;
    column-gap: 25px;
    margin: 40px auto 0;
  }

  .dialog-add-button {
    border: none;
    background: $main-blue;
    color: $white;

    &:hover {
      border: 1px solid $main-blue;
      background: $main-blue;
      color: $white;
      opacity: 0.6;
      cursor: pointer;
    }
  }
}

.table-link {
  color: $main-blue;
  text-decoration: none;

  &:hover {
    opacity: 0.6;
  }
}
