@import "../../../styles/colors";

.home-top-color {
  width: 100%;
  height: 410px;
  position: absolute;
  background-color: $light-blue;
  opacity: 0.6;
  z-index: -1;
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 32px;

  @media screen and (max-width: 768px) {
    top: 0;
    height: 378px;
  }
}

.survey-card {
  flex: 1 1 32%;
  min-width: 250px;
  max-width: 450px;
  border-radius: 16px !important;
}

.survey-card.MuiPaper-root {
  overflow: unset;
  position: relative;
}

.card-container {
  overflow: hidden;
  border-radius: 16px;
}

.pin-survey-icon {
  position: absolute;
  z-index: 1;
  right: -11px;
  top: -11px;
}

@media screen and (min-width: 975px) {
  .survey-card {
    max-width: 32.5%;
  }
}

@media screen and (min-width: 975px) {
  .survey-card {
    max-width: 32.5%;
  }
}

@media screen and (max-width: 974px) and (min-width: 644px) {
  .survey-card {
    max-width: 49%;
  }
}

.survey-card .default-image {
  background-color: $light-blue;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > img {
    height: 80px;
  }
}

.survey-card-tags-wrapper {
  display: flex;
  flex-wrap: wrap;
  column-gap: 8px;
  row-gap: 8px;
}

.survey-card-category-item {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: $dark-gray;

  padding: 4px 8px;
  background-color: $category-background-gray;
  border-radius: 16px;
}

.survey-card-title {
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: $black;
  margin: 16px 0;
  text-decoration: none;
  min-height: 48px;
}

.reward-points-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1px 8px 1px 4px;
  background: $background-orange;
  border-radius: 16px;
  width: auto;
}

.reward-points-label img {
  width: 20px;
  height: 20px;
}

.reward-points-label p {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: $orange;
  margin: 0 auto 0 5px;
}

.completed-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px 12px 3px 8px;
  background: $light-blue;
  border-radius: 16px;
  width: 110px;
  color: $main-blue;
}

.completed-label p {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  margin: 0;
}

.survey-card-actions {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  color: $light-gray;
}

.load-more-button-wrapper {
  text-align: center;
  margin: 0px auto 60px auto;
}

.filter-options-wrapper {
  margin: 10px 10%;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}

.loading-text {
  margin: 220px auto 0;

  @media screen and (max-width: 768px) {
    margin: 100px auto 0;
  }
}
