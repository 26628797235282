@import "/src/styles/colors.scss";

.carousel {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  min-width: 45%;
  background-color: white;

  &-wrapper {
    min-width: 300px;
    max-width: 450px;
    width: 100%;
    padding: 120px 50px;
    text-align: center;
  }

  p {
    font-size: 1rem;
  }

  ul {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    align-items: center;

    img {
      max-height: 6rem;
    }
  }
}

@media screen and (max-width: 850px) {
  .carousel {
    display: none;
  }
}
