@import "/src/styles/colors.scss";

.question-dialog-content {
  width: 624px;
  padding: 25px 35px;
  text-align: center;
  @media screen and (max-width: 767px) {
    max-width: 400px;
  }
  @media screen and (max-width: 600px) {
    max-width: 300px;
  }
  .dialog-title {
    font-size: 19px;
    line-height: 24px;
    color: $black;
    mix-blend-mode: normal;
    margin: 5px auto 15px;
  }
  .dialog-text {
    font-size: 19px;
    line-height: 24px;
    color: $black;
  }

  .buttons-wrapper {
    display: flex;
    justify-content: space-between;
    column-gap: 25px;
    margin-top: 35px;
    .dialog-submit-button {
      text-transform: none;
      margin: 10px 0;
      background: #2EBDEE;
      width: 100%;
      border-radius: 4px;
      border: 0;
      padding: 14px;
      color: white;
    };
    .dialog-decline-button {
      text-transform: none;
      margin: 10px 0;
      background: white;
      width: 100%;
      border-radius: 4px;
      border: 0;
      padding: 14px;
      color: #5F6F74;
    }
  }
}
