@import "../../../styles/colors.scss";

html { scroll-behavior: smooth; } 

.header {
  display: flex;

  h1 {
    flex: 1;
    margin-left: 1rem;
    color: rgb(0, 0, 0, 0.77);
  }
}

h2 {
  color: $dark-gray;
}

h3 {
  color: $black;
}

li {
  padding-left: .5rem;
}

img {
  max-width: 100%;
}

.link-example {
  color: $dark-gray;
  overflow-wrap: break-word;
}

.query-params {
  margin: 1rem 0;
  color: $black;
}
