@import "/src/styles/colors.scss";

.banner-wrapper {
  display: flex;
  align-items: center;
  width: 90%;
  max-width: 300px;
  height: 36px;
  background: $white;
  box-shadow: 3px 16px 20px rgba(0, 0, 0, 0.03);
  border-radius: 32px;
  padding: 15px;
  margin: 20px;

  .avatar-wrapper {
    min-width: 34px;
    min-height: 34px;
    max-width: 34px;
    max-height: 34px;
    border: 1px solid $lighter-gray;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $black;
    background-color: $light-gray;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .banner-info-wrapper {
    display: block;
    margin: 0 auto 0 15px;
    width: 100%;

    .bonus-points-wrapper {
      display: flex;
      align-items: center;
      width: 100%;

      & > img {
        width: 20px;
        height: 20px;
      }

      & > p {
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: $black;
        margin: 0 auto 0 10px;
      }
    }

    .progress-bar-wrapper {
      display: flex;
      width: 100%;
      align-items: center;

      .progress-bar-text {
        font-weight: bold;
        font-size: 12px;
        line-height: 12px;
        text-align: right;
        color: $black;
        margin: 0 auto 0 5px;
        min-width: fit-content;
      }
    }
  }
}

@media screen and (max-width: 1130px) and (min-width: 721px) {
  .banner-wrapper {
    max-width: 400px;
    height: 75px;
    order: 2;
    margin: 20px auto;
  }
}

@media screen and (max-width: 660px) {
  .banner-wrapper {
    max-width: 400px;
    height: 75px;
    order: 2;
    margin: 20px auto;
  }
}
