@import "styles/colors.scss";

body {
  margin: 0;
  // background: linear-gradient(150.73deg, #FFFFFF 13.82%, #E8FAFF 77.36%);
  // background-image: url(#public/img/Vector.png);
  font-family: "Roboto", sans-serif;
  color: $default-black;
}

#rf-launcher > div {
  right: unset !important;
  left: 30px;
}

#rf-widget {
  right: unset !important;
  left: 30px !important;
}

// * {
//   border: 1px solid rgba(0, 0, 0, 0.2)
// }
