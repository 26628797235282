@import "/src/styles/colors.scss";

.content-wrapper {
  margin: 60px auto;
  padding: 0 30px;
  max-width: 80%;
}

.header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

p.title {
  font-weight: bold;
  font-size: 27px;
  line-height: 32px;
  color: $secondary-blue;
  margin: 0 0 10px;
}

p.subtitle {
  font-size: 16px;
  line-height: 24px;
  color: $gray;
  margin: 0;
}

tbody {
  background-color: $white;
  box-shadow: 3px 24px 20px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
}

tbody td {
  padding: 15px;
  border-top: 1px solid $lighter-gray;
}
.tag{
  font-weight: 700;
  background: #F8FAFA;
  border-radius: 16px;
  align-items: center;
  padding: 4px 8px; 
}
.status-cell {
  min-width: 70px;
}

.status-cell.active {
  color: $active-green;
}

.status-cell.inactive {
  color: $inactive-red;
}

.status-cell::before {
  content: "";
  display: inline-block;
  width: 3px;
  height: 3px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
  border: 2px solid;
  background-color: $white;
  margin: 1px 5px;
}

.filters-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.filter-wrapper,
.actions-button {
  position: relative;
}

.filter-options {
  padding: 0 25px 25px;
}

.filter-options {
  .option-title {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    margin: 20px 0 0;
    color: $gray;
  }

  .response-rate-fileds {
    display: flex;
    justify-content: space-between;
    column-gap: 25px;
    margin-top: 15px;
    color: gray;
  }

  .buttons-wrapper {
    display: flex;
    justify-content: space-evenly;
    column-gap: 30px;
    margin-top: 30px;
  }
}

.actions-options {
  padding: 10px 0;

  span {
    font-size: 16px;
    line-height: 24px;
    color: $black;
  }
}

.dialog-content {
  width: 25rem;
  padding: 25px 35px;
  text-align: center;

  .dialog-icon-container {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: rgba(236, 58, 90, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 20px;
  }
  .dialog-icon-wrapper {
    width: 33px;
    height: 33px;
    border-radius: 50%;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .disable-icon {
    height: 24px;
    color: $white;
  }

  .dialog-title {
    font-size: 19px;
    line-height: 24px;
    font-weight: bold;
    color: $black;
    mix-blend-mode: normal;
    margin: 5px auto 15px;
  }

  .dialog-user-name {
    font-size: 16px;
    line-height: 24px;
    color: $dark-gray;
    mix-blend-mode: normal;
    margin: 5px auto 15px;
  }

  .buttons-wrapper {
    display: flex;
    justify-content: center;
    column-gap: 25px;
    margin-top: 35px;
  }

  .dialog-disable-button {
    border: none;
    background: $inactive-red;
    color: $white;

    &:hover {
      border: 1px solid $inactive-red;
      background: $inactive-red;
      color: $white;
      opacity: 0.6;
      cursor: pointer;
    }
  }

  .dialog-add-points-subtitle{
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-align: center;
    margin-top: 40px;
    color: $black
  }

  .dialog-upload-button {
    border: none;
    background: $main-blue;
    color: $white;

    &:hover {
      border: 1px solid $main-blue;
      background: $main-blue;
      color: $white;
      opacity: 0.6;
      cursor: pointer;
    }
  }
  .dialog-add-csv-file{
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $dark-gray;
    width: 264px;
    height: 40px;
    border: 1px solid $dark-gray;
    border-radius: 4px;
    text-transform: capitalize;
    padding: 10px 83px 10px 83px;
  }
  .dialog-add-csv-file-hover:hover {
    border: 1px solid $dark-gray;
    background-color: transparent;
    cursor: pointer;
    opacity: 0.6;
  }
}

.utmList {
  padding: 0px;
  margin: 0;
}