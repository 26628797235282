@import "src/styles/colors";

.contact-us-paper {
  width: 390px;
  height: 450px;
  @media screen and (max-width: 600px) {
    width: 100%;
    height: 100%;
  }
  .contact-us-dialog {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.11), rgba(255, 255, 255, 0.11)), #ffffff;
    margin: 24px;
    display: flex;
    flex-direction: column;
    .contact-us-close {
      position: absolute;
      top: 27px;
      right: 27px;
      width: fit-content;
      cursor: pointer;
      @media screen and (max-width: 600px) {
        width: 100%;
        left: 22px;
      }
    }
    .contact-us-header {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 27px;
      line-height: 32px;
      color: #384144;
      text-align: center;
      margin-bottom: 30px;
      @media screen and (max-width: 600px) {
        margin-top: 43px;
      }
    }
    .contact-us-label {
      margin-bottom: 12px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.5px;
      color: #bac6ca;
    }
    .support-body {
      height: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .support-body-button {
      margin-left: 1rem;
      margin-right: 1rem;
      min-width: 150px;
    }

    .contact-us-submit {
      border: none;
      height: 40px;
      width: 343px;
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      bottom: 40px;
      color: #ffffff;
      text-transform: none;

      &:hover {
        background: #2ebdee;
        opacity: 0.7;
      }
    }
  }
}
