#survey-display-container {
  //height: 1000px;
}
#qualtrics-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}
#qualtrics-iframe {
  width: 100%;
  height: 800px;
  border: 0 solid #000;
  border-radius: 10px;
}

.external-survey-card {
  display: flex;
  justify-content: center;
}
