@import "/src/styles/colors.scss";

.data-row {
  display: flex;
  margin: 30px auto;
  column-gap: 24px;
}

.data_unit {
  max-width: 344px;
  width: 100%;
}

.source-container::placeholder {
  text-transform: capitalize;
}