@import "/src/styles/colors.scss";

.nav-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 20px 40px;

  .logo-wrapper {
    width: 110px;
    height: 75px;
    min-width: 110px;
    margin: 20px;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .logout-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $dark-gray;
    cursor: pointer;

    svg {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }

  .nav-menu-items-wrapper-desktop {
    display: flex;
    flex-grow: 1;
  }
  .nav-menu-items-wrapper {
    display: none;
    align-items: center;
  }
  @media screen and (max-width: 720px) {
    .logo-wrapper {
      display: none;
    }
    .nav-menu-items-wrapper-desktop {
      display: none;
    }
    .nav-menu-items-wrapper {
      display: flex;
      flex-grow: 1;
    }
  }

  .nav-menu-item {
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 24px;
    letter-spacing: 1px;
    color: $dark-gray;
    margin: 0 16px;
    text-transform: uppercase;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
      color: $default-black;
    }
  }

  @keyframes spin {
    0% {
      transform: rotateZ(0deg);
    }
    25% {
      transform: rotateZ(30deg);
    }
    50% {
      transform: rotateZ(-30deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }

  .conversion-points-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 8px 4px 4px;
    background: $background-orange;
    border-radius: 16px;
    margin: 20px;
    min-width: 88px;

    & > img {
      width: 25px;
      height: 25px;
      animation: 4s linear 1s infinite both spin;
    }

    & > p {
      font-size: 20px;
      color: $orange;
      margin: 0 auto 0 5px;
    }
  }
}
