@import "../../../../styles/colors";

.load-more-button {
  display: flex !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.activity-row {
  &.failed {
    background-color: $danger;
    color: red;
  }
}

.error-message {
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  font-family: "Courier New", Courier, monospace;
  white-space: pre-wrap;
}

.cell-content {
  display: flex;
  align-items: center;
  gap: 8px;
}

.expand-button {
  margin-right: 8px;
}
