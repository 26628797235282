#privacy-policy-wrapper {
  display: flex;
  flex-direction: column;
  padding-top: 40px;

  .effective-date {
    align-self: center;
  }

  .privacyImportant {
    margin: 0 100px;
    padding: 20px 40px;
    border: 2px solid #2077bd;
  }

  .privacyImportant p {
    text-align: center;
  }
}
