@import "/src/styles/colors.scss";

.carousel {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  min-width: 45%;

  &-wrapper {
    min-width: 300px;
    max-width: 450px;
    width: 100%;
    padding: 120px 50px;
    text-align: center;

    & > p {
      font-size: 27px;
      line-height: 32px;
      color: $secondary-blue;
      max-width: 350px;
      margin: 30px auto;
    }

    & > img {
      width: 100%;
      height: 100%;
    }
  }

  &-navigator {
    display: flex;
    column-gap: 15px;
    max-width: 75px;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto 70px;

    &-dot {
      width: 15px;
      height: 15px;
      margin: 0;
      border-radius: 50%;
      background: $main-blue;
      opacity: 0.3;
    }

    &-dot.active {
      opacity: 1;
    }
  }
}

@media screen and (max-width: 850px) {
  .carousel {
    display: none;
  }
}
