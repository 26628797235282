@import "/src/styles/colors.scss";

.content-wrapper {
  margin: 60px auto;
  padding: 0 30px;
  max-width: 80%;
}

p.title {
  font-weight: bold;
  font-size: 27px;
  line-height: 32px;
  color: $secondary-blue;
  margin: 0 0 10px;
}

.settings-tabs-wrapper {
  display: flex;
  align-items: center;
  column-gap: 30px;
  margin: 30px 0 15px 0;
}

p.subtitle {
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: $black;
  margin: 0 0 30px;
}

.account-and-security-container {
  .user-profile-image-wrapper {
    display: flex;
    flex-wrap: wrap;
    column-gap: 15px;
    margin-bottom: 60px;
    justify-content: flex-start;
    align-items: center;

    .image-wrapper {
      margin: 0;
      width: 120px;
      height: 120px;
      border: 1px solid $lighter-gray;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .default-image {
      background-color: $light-gray;
      color: $black;

      svg {
        width: 90px;
        height: 90px;
      }
    }
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    column-gap: 25px;
    row-gap: 30px;
    margin: 30px 0;

    & > div {
      flex-grow: 1;
      min-width: 200px;
      max-width: 500px;
    }
  }

  .change-image-label,
  .change-password-link {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: $main-blue;
    cursor: pointer;
    margin: 0;

    &:hover {
      opacity: 0.7;
    }
  }
}

.point-conversion-row {
  display: flex;
  flex-wrap: nowrap;
  column-gap: 15px;
  align-items: center;

  & > span {
    font-weight: bold;
    font-size: 27px;
    line-height: 32px;
    color: $black;
    margin: 0;
  }

  input {
    width: 242px;
  }
}

.change-password-dialog {
  padding: 30px 60px;
  max-width: 400px;

  .title {
    font-size: 24px;
    line-height: 32px;
    font-weight: bold;
    color: $black;
    text-align: center;
    mix-blend-mode: normal;
    margin: 0 auto 40px;
  }

  .change-password-form {
    .input-wrapper {
      width: 100%;
    }

    .new-password-inputs-wrapper {
      flex-direction: column;
    }
  }
}

span.info-label {
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  color: $light-gray;
  letter-spacing: 0.5px;
  margin: 0;
  display: block;
  max-width: 25rem;
  margin-bottom: 5px;
}

.bonus-points-row {
  display: flex;
  flex-wrap: nowrap;
  column-gap: 15px;
  margin: 0 0 30px;

  input {
    width: 350px;
  }
}

.bonus-points-row > div {
  width: 350px;
}
