@import "/src/styles/colors.scss";

.change-password-form {
  .label {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    margin: 0 0 4px;
    color: $black;
  }

  .input-wrapper {
    max-width: 400px;
    min-width: 250px;
    width: 48%;
    margin: 12px 0;
  }

  .input-container {
    width: 100%;
    border-radius: 8px;
  }

  .new-password-inputs-wrapper {
    display: flex;
    column-gap: 25px;
    flex-wrap: wrap;
  }

  .checklist {
    margin: 20px 0;

    p {
      font-size: 12px;
      line-height: 12px;
      color: $light-gray;
      display: flex;
      align-items: center;
      column-gap: 8px;
    }

    svg {
      width: 18px;
    }

    p.active {
      color: $black;

      svg {
        color: $main-blue;
      }
    }
  }

  .buttons-wrapper {
    display: flex;
    justify-content: space-between;
    column-gap: 25px;
    margin: 40px auto 0;
  }

  .dialog-save-button,
  .large-save-button {
    border: none;
    background: $main-blue;
    color: $white;

    &:hover {
      border: 1px solid $main-blue;
      background: $main-blue;
      color: $white;
      opacity: 0.7;
      cursor: pointer;
    }

    &:disabled {
      border: 1px solid $gray;
      background: $lighter-gray;
    }
  }

  .large-save-button {
    padding: 12px 70px;
    width: auto;
    height: auto;
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) {
  .change-password-form {
    max-width: 400px;
    margin: 0 auto 60px;

    .input-wrapper {
      width: 100%;
    }

    .new-password-inputs-wrapper {
      flex-direction: column;
    }

    .large-save-button {
      width: 100%;
      max-width: 400px;
    }
  }
}
